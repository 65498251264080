import React, { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Form,
  message,
  UploadProps,
  Button,
  Input,
  Select,
  Checkbox,
  Upload,
} from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useDispatch } from 'react-redux'
import {
  createAdminUser,
} from '../appRedux/actions'
import { CountryCode, regionNames } from '../constants/CountryCode'
import { Permission } from '../constants/Permission'
import {
  STATUS_CODE_CREATE_SUCCESS,
} from '../constants/StatusCode'
import IntlTranslate from '../util/IntlTranslate'
import { injectIntl } from 'react-intl'
import IntlMessages from '../util/IntlMessages'
import { SystemUsersApi } from '../client-axios'
import { axiosInstance } from '../core/axios'
import { configHeader } from '../core/token'
const sysUser = new SystemUsersApi(configHeader(), undefined, axiosInstance)
const RegisterUser = (props: any) => {
  const {
    intl,
    contact,
  } = props
  const t = IntlTranslate(intl)
  const [isLocked, setLocked] = useState(false)
  const [loadings, setLoadings] = useState(false)
  const [locations, setLocations] = useState<
    { label: string; value: string }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<any | undefined>()
  const [imageUrl, setImageUrl] = useState<string>()
  const [infoImg, setInfoImg] = useState<any>({
    avatarLarge: null,
    avatarMedium: null,
    avatarOriginal: null,
    avatarSmall: null,
  })
  const [form] = Form.useForm()
  const mimeType = 'image/png'
  const dispatch = useDispatch()
  useEffect(() => {
    const newLocations = CountryCode.map((code) => {
      return {
        value: code,
        label: regionNames(code) || 'N/A',
      }
    })
    setLocations(newLocations)
  }, [])

  useEffect(() => {
    if (contact && contact.id) {
      form.setFieldsValue({
        ...contact,
      })
      setLocked(contact?.isLocked)
    }
  }, [form, contact])
  const onFinish = async () => {
    const values = await form.validateFields()
    setLoadings(true)
    const checkCode = await dispatch(
      createAdminUser({
        ...values,
        avatarOriginal: infoImg.avatarOriginal,
        avatarSmall: infoImg.avatarSmall,
        avatarMedium: infoImg.avatarMedium,
        avatarLarge: infoImg.avatarLarge,
        isLocked,
      })
    )
    if (checkCode === STATUS_CODE_CREATE_SUCCESS) {
      setLoadings(false)
      message.success(t('notify.create.user.admin.success'), 8)
      props.history.push(`/signin`)
    } else {
      setLoadings(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }
  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    const response = await sysUser.uploadedFileWithSysusers(file)
    if (response.status === 201) {
      setInfoImg(response.data)
      getBase64(file as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    } else {
      message.error(`${file.name} file upload failed.`)
    }
    return false
  }
  const validateEmailDomain = async (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Please input your email!'))
    }
    return Promise.resolve()
  }
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined rev={undefined} />
      ) : (
        <PlusOutlined rev={undefined} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 24,
    },
  }
  const propsUpload: UploadProps = {
    beforeUpload: beforeUpload,
  }
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-register-content">
            <Form {...layout} form={form}>
              <div className="gx-modal-box-form-item">
                <div className="gx-form-group">
                  <Form.Item
                    name="displayName"
                    className="margin-bottom-0"
                    label={t('userManagement.userName')}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject(t('Form.Required'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                <div className="gx-form-group">
                  <Form.Item
                    name="email"
                    className="margin-bottom-0"
                    label={t('userManagement.email')}
                    rules={[
                      { message: 'Email is required!' },
                      { type: 'email', message: 'Please enter a valid email!' },
                      { validator: validateEmailDomain },
                    ]}
                  >
                    <Input placeholder={t('userManagement.email')} />
                  </Form.Item>
                </div>

                <div className="gx-form-group">
                  <Form.Item
                    requiredMark={'optional'}
                    name="company"
                    className="margin-bottom-0"
                    label={t('userManagement.company')}
                  >
                    <Select placeholder={t('userManagement.company')} />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    requiredMark={'optional'}
                    name="location"
                    className="margin-bottom-0"
                    label={t('userManagement.location')}
                  >
                    <Select
                      allowClear
                      placeholder={t('userManagement.location')}
                    >
                      {locations.map((item) => {
                        return (
                          <Select.Option
                            key={'country' + item.value}
                            value={item.value}
                          >
                            {item.label}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    name="permissionGroups"
                    className="margin-bottom-10"
                    label={t('userManagement.permission')}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(t('Form.Required'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <Select placeholder={t('userManagement.permission')}>
                      {Permission.map((item, index) => {
                        return (
                          <Select.Option
                            value={item}
                            key={'permission' + index}
                          >
                            {item}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    className="margin-bottom-0"
                    label={t('userManagement.locked')}
                    name="isLocked"
                  >
                    <Checkbox
                      checked={isLocked}
                      onChange={() => setLocked(!isLocked)}
                    />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item className="margin-bottom-0" label={'Avatar'}>
                    <Upload
                      {...propsUpload}
                      name="image"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : contact?.avatarLarge ? (
                        <img
                          src={
                            avatar &&
                            `data:${mimeType};base64,${Buffer.from(
                              avatar.Body
                            ).toString('base64')}`
                          }
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>

                  <Button
                    type="primary"
                    className="gx-mb-0 float-right"
                    htmlType="submit"
                    loading={loadings}
                    onClick={onFinish}
                  >
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(RegisterUser)
